import $ from 'jquery';
import sendNewsletterRequest from './send-newsletter-request';

export const FORM_ID = 'careersApplication';
export const FILE_ID = 'resume';
const EVENT_NAMESPACE = '.careers-form';

export function careersSubmit() {
  const $form = $(`#${FORM_ID}`);
  const $uploadBox = $(`#${FILE_ID}`).closest('.c-upload-box');

  $uploadBox.on('upload-box:file-update', (event, data) => {
    applySubmitEvent($form, data);
  });
  applySubmitEvent($form);
  $form.on('submit', event => event.preventDefault() );
}

function applySubmitEvent ($form, additionalData) {
  $form.off(EVENT_NAMESPACE).on(`submit${EVENT_NAMESPACE}`, () => {
    const formData = getFormDataAndRemoveDuplicate($form, additionalData);
    const isValid = $form.parsley().isValid();
    const method = $form.attr('method');
    const url = $form.attr('action');

    if (additionalData) {
      formData.append(additionalData.name, additionalData.data);
    }

    if (!isValid) {
      return;
    }

    const $submitButtons = $form.find('button[type=submit], input:submit');
    const $message = $( $form.data('messageSelector') );
    const isNewsletter = $form.find('input[name=subscribe]').is(':checked');
    const formDeferred = sendFormData(url, method, formData);
    let newsletterDeferred = $.Deferred();

    if (isNewsletter) {
      const newsletterUrl = $form.data('newsletterUrl');
      const newsletterData = $form.find('input.js-newsletter-input').serialize();

      newsletterDeferred = sendNewsletterRequest(newsletterUrl, newsletterData);
    } else {
      newsletterDeferred.resolve();
    }

    $form
      .addClass('is-loading')
      .off(EVENT_NAMESPACE);
    $submitButtons
      .on('click', event => event.preventDefault() )
      .addClass('is-loading');

    $.when(formDeferred, newsletterDeferred)
      .always( () => {
        $submitButtons
          .add($form)
          .removeClass('is-loading');
      })
      .then( (formArguments = [], newsletterArguments = []) => {
        const ERROR_STATUS = 400;
        const [ formResponse ] = formArguments;
        const [ newsletterResponse ] = newsletterArguments;

        if (
          $.type(newsletterResponse) === 'object' &&
          newsletterResponse.Status === ERROR_STATUS
        ) {
          handleMixed($form, $message, formResponse, newsletterResponse);
        } else if (
          $.type(formResponse) === 'object' &&
          formResponse.success === false
        ) {
          handleError($form, $message, formResponse.message);
        } else {
          handleSuccess($form, $message, formResponse);
        }
      })
      .catch( (response, status, error) => {
        handleError($form, $message, error);
      });
  });
}

function handleMixed ($form, $message, formResponse, newsletterResponse) {
  $message
    .add($form)
    .addClass('is-mixed');

  if (
    $.type(formResponse) === 'object' && formResponse.message &&
    newsletterResponse.Message
  ) {
    $message.text(`
      ${newsletterResponse.Message}
      ${formResponse.message}
    `)
  }
}

function handleSuccess ($form, $message, response) {
  $message
    .add($form)
    .addClass('is-success');

  $form.trigger('reset');

  if ($.type(response) === 'object' && response.message) {
    $message.text(response.message)
  }
}

function handleError ($form, $message, text) {
  const messageBefore = $form.data('errorMessageBefore');
  const messageFull = $form.data('errorMessageFull');

  $message
    .add($form)
    .addClass('is-error');

  if (text) {
    $message.text(`${messageBefore} ${text}`);
  } else {
    $message.text(messageFull);
  }
}

function sendFormData (url, method, data) {
  return $.ajax({
    method,
    url,
    data,
    dataType: 'json',
    processData: false,
    contentType: false
  });
}

function getFormDataAndRemoveDuplicate ($form, additionalData) {
  if (additionalData) {
    const { name } = additionalData;
    $form.find('input').filter(`[name="${name}"]`).remove();
  }

  return new FormData($form[0]);
}
